import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/help-center'
  },
  {
    path: '/help-center',
    name: 'HelpCenter',
    meta: {
      title: '帮助中心'
    },
    component: () => import(/* webpackChunkName: "helpCenter" */ '@/views/helpCenter/HelpCenter.vue')
  },
  {
    path: '/preview-payment',
    name: 'PreviewPayment',
    meta: {
      title: '预览'
    },
    component: () => import(/* webpackChunkName: "previewPayment" */ '@/views/PreviewPayment.vue')
  },
  {
    path: '/course',
    name: 'Course',
    meta: {
      title: '视频教程'
    },
    component: () => import(/* webpackChunkName: "Course" */ '@/views/Course.vue')
  },
  {
    path: '/billing-apply',
    name: 'BillingApply',
    meta: {
      title: '开票申请'
    },
    component: () => import(/* webpackChunkName: "BillingApply" */ '@/views/billingApply/BillingApply.vue')
  },
  {
    path: '/billing-apply-search',
    name: 'BillingApplySearch',
    meta: {
      title: '开票申请查询'
    },
    component: () => import(/* webpackChunkName: "BillingApplySearch" */ '@/views/billingApply/BillingApplySearch.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/course' || to.path === '/billing-apply') {
    document.title = to.meta.title as string
  } else {
    document.title = ''
  }
  next()
})

export default router
